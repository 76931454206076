<template>
    <v-container>
        <v-stepper v-model="stepper">
            <v-stepper-header>
                <v-stepper-step
                    :complete="stepper > 1"
                    step="1"
                >
                    选择文件
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    :complete="stepper > 2"
                    step="2"
                >
                    确定参数
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">
                    完成
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card
                        class="mb-12"
                        elevation="0"
                    >
                        <v-file-input label="上传文件" type="file" @change="changeHandler"></v-file-input>
                    </v-card>

                    <v-btn
                        color="primary"
                        @click="stepper = 2"
                        :disabled="!fileLoaded"
                    >
                        下一步
                    </v-btn>

                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-card
                        class="mb-12"
                        elevation="0"
                        v-if="fileLoaded"
                    >
                        <v-expansion-panels accordion>
                            <v-expansion-panel
                                v-for="(sheetName, i) in workbook.SheetNames"
                                :key="i"
                            >
                                <v-expansion-panel-header>{{sheetName}}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row
                                        v-for="(colName, index) in workbook.Sheets[sheetName].jsonData[0]"
                                        :key="index"
                                    >
                                        <v-col cols="4">
                                            <v-subheader>{{colName}}</v-subheader>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-select
                                                label="选择类型"
                                                dense
                                                :items="types"
                                                item-text="text"
                                                item-value="value"
                                                v-model="workbook.Sheets[sheetName].typeList[index]"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>

                    <v-btn
                        color="primary"
                        @click="convertWorkbook"
                    >
                        开始转换
                    </v-btn>

                    <v-btn text @click="stepper = 1">
                        上一步
                    </v-btn>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-card
                        class="mb-12"
                        elevation="0"
                    >
                        <v-card-text>
                            <v-alert
                                type="success"
                            >转换完成</v-alert>
                        </v-card-text>
                    </v-card>

                    <v-btn
                        color="primary"
                        @click="stepper = 1"
                    >
                        再来一次
                    </v-btn>

                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-container>
</template>

<script>
import XLSX from 'xlsx';

export default {
    name: 'Home',
    data: () => ({
        workbook: null,
        fileLoaded: false,
        stepper: 1,
        types: [
            { text: '重量（公斤转磅）', value: 'weight' },
            { text: '长度（厘米转英寸）', value: 'len' },
            { text: '电话号码', value: 'phone' }
        ]
    }),
    methods: {
        async changeHandler(e) {
            console.log(e);
            const file = e;
            const data = await file.arrayBuffer();
            let workbook = XLSX.read(data);
            console.log(workbook);
            this.workbook = workbook;
            workbook.SheetNames.forEach(sheetName => {
                let sheet = workbook.Sheets[sheetName];
                let sheetJson = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: true });
                this.workbook.Sheets[sheetName].jsonData = sheetJson;
                this.workbook.Sheets[sheetName].typeList = [];
            });
            this.fileLoaded = true;
        },
        convertWorkbook() {
            this.workbook.SheetNames.forEach(SheetName => {
                let sheet = this.workbook.Sheets[SheetName];
                Object.keys(sheet.typeList).forEach( key => {
                    if (sheet.typeList[key] === 'weight') {
                        sheet.jsonData.forEach( (row, idx) => {
                            if (idx === 0) return;
                            let weight = parseFloat(row[key]);
                            weight = weight * 2.2046;
                            let target = XLSX.utils.encode_cell({c: parseInt(key), r: idx});
                            sheet[target].v = weight;
                            delete sheet[target].w;
                            // sheet.jsonData[idx][key] = weight;
                        });
                    }
                    if (sheet.typeList[key] === 'len') {
                        sheet.jsonData.forEach( (row, idx) => {
                            if (idx === 0) return;
                            let len = parseFloat(row[key].slice(0, -2));
                            len = len * 0.3937;
                            let target = XLSX.utils.encode_cell({c: parseInt(key), r: idx});
                            sheet[target].v = Math.round(len);
                            delete sheet[target].w;
                            // sheet.jsonData[idx][key] = len.toFixed(2) + 'IN';
                        });
                    }
                    if (sheet.typeList[key] === 'phone') {
                        sheet.jsonData.forEach( (row, idx) => {
                            if (idx === 0) return;
                            if (!row[key]) return;
                            let phone = row[key].replace('+1', '').replace(/\D/g,'').slice(0, 10);
                            let target = XLSX.utils.encode_cell({c: parseInt(key), r: idx});
                            sheet[target].v = phone;
                            delete sheet[target].w;
                            // sheet.jsonData[idx][key] = phone;
                        });
                    }
                });
                // this.workbook.Sheets[SheetName] = XLSX.utils.json_to_sheet(sheet.jsonData);
                this.workbook.Sheets[SheetName] = sheet;
            });
            XLSX.writeFile(this.workbook, 'output.xlsx');
            this.stepper = 3;
        }
    }
}
</script>
